.App {
  text-align: center;
  width: 100%;
  background-image: url("./assets/images/detroit2.jpg");
  background-size: cover;
  background-attachment: fixed;
}

svg {
  font-size: 3rem;
  color: #407c7f;
}

svg:hover {
  color: #957d7f;
}

.nav-link {
  font-size: 1.5rem;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Roboto Condensed", sans-serif;
  color: white;
}

.nav-link:hover {
  color: #1d7b7e;
  text-decoration-line: underline;

  /* background: #ffffff57; */
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.2);
}

.card {
  max-width: 100%;
  height: 100%;
}

.sc-gZMcBi {
  height: 75%;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
}
